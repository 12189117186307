import { Injectable } from '@angular/core';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
  @Injectable({
    providedIn: 'root'
  })
  export class TranslateConfigService {

    public title: string;
    public title_2: string;
    public description: string;
    public name: string;
    public language: string;

    constructor(
      private globalization: Globalization, 
      private _translate: TranslateService,
      private http: HttpClient 
    ){}

    
  
    _initialiseTranslation():  void {
      this._translate.get('TITLE').subscribe((res: string) => {
        this.title = res;
      });
      this._translate.get('description').subscribe((res: string) => {
        this.description = res;
      });
      this._translate.get('TITLE_2', { value: 'John' }).subscribe((res: string) => {
        this.title_2 = res;
      });
      this._translate.get('data.name', { name_value: 'Marissa Mayer' }).subscribe((res: string) => {
        this.name = res;
      });
  
    }
  
  
    _translateLanguage(): void {
      console.log('language', this.language);
      this._translate.use(this.language);
      this._initialiseTranslation();
    }
  
    _initTranslate(language) {
      // Set the default language for translation strings, and the current language.
      this._translate.setDefaultLang('en');
      if (language) {
        this.language = language;
      }
      else {
        // Set your language here
        this.language = 'en';
      }
      this._translateLanguage();
    }
  
    getDeviceLanguage() {
      if (window.Intl && typeof window.Intl === 'object') {
        this._initTranslate(navigator.language)
      }
      else {
        this.globalization.getPreferredLanguage()
          .then(res => {
            this._initTranslate(res.value)
          })
          .catch(e => {console.log(e);});
      }
    }
  }