import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class AppData {

    MODE: string = 'LIVE'; // i think you uploaded it with LIVE1yes but i did Live in browser version and it didnt connected i left it like this iand it worked
    //API_URL_DEV: string = 'http://192.168.64.2/PHP-Slim-Restful/api/'; 
    API_URL_DEV: string = 'https://www.rovespier.com/rovespier_api/api/';
    //API_URL_DEV: string = 'http://192.168.1./PHP-Slim-Restful/api/';

    //API_URL: string = 'http://192.168.64.2/PHP-Slim-Restful/api/';
    API_URL: string = 'https://rovespier.com/rovespier_api/api/';
    APP_PREFIX: string = 'Rov_';
    APP_VERSION: string = '1.0.1';
    APP_BUILD: string = '100';
    STORES: any = {
        'ios': '',
        'android': '',
        'browser': ''//should i put this here?no need check now if its connecting i ll have to create apk from beggining
    };

    constructor(public http: HttpClient) {
        console.log('Hello AppProvider Provider');
    }

    getApiUrl() {
        if (this.MODE != 'LIVE') {
            return this.API_URL_DEV;
        }
        return this.API_URL;// thats an image profile
    }

    getPrefix() {
        return this.APP_PREFIX;
    }

    getAppVersion() {
        return this.APP_VERSION;
    }

    getAppBuild() {
        return this.APP_BUILD;
    }

    getStores() {
        return this.STORES;
    }

}
