import { Component, OnInit } from '@angular/core';

import { Router, NavigationExtras } from "@angular/router";

import { User } from 'src/app/interfaces/user';

@Component({
  selector: 'app-user-profile-popover',
  templateUrl: './user-profile-popover.component.html',
  styleUrls: ['./user-profile-popover.component.scss'],
})
export class UserProfilePopoverComponent implements OnInit {
  userProfile: User = null;

  item_qty:any;

  mediaAds: any = [];

  constructor(
    private router:Router
  ) {
    this.item_qty=1;
   }

  ngOnInit() {
  }

  inc(){
    this.item_qty += 1;
    console.log(this.item_qty + 1);
    }
    
    //decrements item
    
    dec(){
      if(this.item_qty-1 < 1){
        this.item_qty = 1;
        console.log('item_1->' + this.item_qty)
      }
      else{
        this.item_qty -= 1;
        console.log('item_2->' + this.item_qty);
      }
   }

   goUsers(mediaAds: any) {
    const navigationExtras: NavigationExtras = { 
      state: {
        mediaAds: this.mediaAds
      }
    };
    this.router.navigate(['app/tablinks/home/users'], navigationExtras);
  }

}
