import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";
import { GuestPageModule } from "./pages/guest/guest.module";


const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full'},
//  { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule), canActivate: [AuthGuard] },
  { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomePageModule) 
  }, 
  {path: 'app', loadChildren: () => import('./pages/tablinks/tablinks.module').then(m => m.TablinksPageModule)
  },
  {
    path: 'guest',
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/guest/guest.module').then(m => m.GuestPageModule)
      },
      { path: 'user', loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule) },
  ]
  },
 { path: 'media',
  loadChildren: () => import('./pages/media/media.module').then(m => m.MediaPageModule)
},
  { path: 'user', loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule) },
  { path: 'login', loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginPageModule) 
  },
  { path: 'signup', loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterPageModule) },
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
  { path: 'terms', loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsPageModule) },
  { path: 'eula', loadChildren: () => import('./pages/eula/eula.module').then(m => m.EULAPageModule)},
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule) },
  { path: 'privacy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule)},
  /*{ path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
  { path: 'user-profile', loadChildren: () => import('./pages/user-profile/user-profile.module').then(m => m.UserProfilePageModule) },
  { path: 'messages', loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule) },
  { path: 'notifications', loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)  },
  { path: 'all-flows', loadChildren: () => import('./pages/all-flows/all-flows.module').then(m => m.AllFlowsPageModule) },
  { path: 'all-media', loadChildren: () => import('./pages/all-media/all-media.module').then(m => m.AllMediaPageModule) },
  { path: 'flow-profile', loadChildren: () => import('./pages/flow-profile/flow-profile.module').then(m => m.FLOWPROFILEPageModule) },
  { path: 'create-flow', loadChildren: () => import('./pages/create-flow/create-flow.module').then(m => m.CreateFlowPageModule)  },
  { path: 'media', loadChildren: () => import('./pages/media/media.module').then(m => m.MediaPageModule) },
  { path: 'top-media', loadChildren: () => import('./pages/top-media/top-media.module').then(m => m.TopMediaPageModule) },
  { path: 'top-reviews', loadChildren: () => import('./pages/top-reviews/top-reviews.module').then(m => m.TopReviewsPageModule) },
  { path: 'media-points', loadChildren: () => import('./pages/media-points/media-points.module').then(m => m.MediaPointsPageModule) },
  { path: 'user',loadChildren: () => import('./pages/user/user.module').then(m => m.UserPageModule) },
  { path: 'review-media', loadChildren: () => import('./pages/review-media/review-media.module').then(m => m.ReviewMediaPageModule) },
  { path: 'user-believers', loadChildren: () => import('./pages/user-believers/user-believers.module').then(m => m.UserBelieversPageModule) },
  { path: 'user-media', loadChildren: () => import('./pages/user-media/user-media.module').then(m => m.UserMediaPageModule) },
  { path: 'user-flows', loadChildren: () => import('./pages/user-flows/user-flows.module').then(m => m.UserFlowsPageModule) },
  { path: 'user-profile-modal', loadChildren: () => import('./pages/user-believers/user-believers.module').then(m => m.UserBelieversPageModule) },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
  { path: 'edit-flow', loadChildren: () => import('./pages/edit-flow/edit-flow.module').then(m => m.EditFlowPageModule)},
  { path: 'upload', loadChildren: () => import('./pages/upload/upload.module').then(m => m.UploadPageModule)},
  { path: 'about', loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule)},
  { path: 'edit-media', loadChildren: () => import('./pages/edit-media/edit-media.module').then( m => m.EditMediaPageModule)},
  { path: 'single-message', loadChildren: () => import('./pages/single-message/single-message.module').then( m => m.SingleMessagePageModule)},
  { path: 'search', loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)},*/
];
//i commented all routs from here to work only from tablinks or tabguest
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
