import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';
import { Plugins, PluginRegistry, Capacitor } from '@capacitor/core';
import { UserData } from './services/user-data/user-data';
import { GlobalService } from './services/global.service/global.service';
import {FacebookLogin} from "@capacitor-community/facebook-login";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    providers: [GlobalService],
})

export class AppComponent implements OnInit {
    dark: any;

    constructor(
        private platform: Platform,
       // private splashScreen: SplashScreen,
        //private statusBar: StatusBar,
        public userData: UserData,
        private globalSrvc: GlobalService,
        private paypalRef: ElementRef,
        private storage: Storage

    ) {
        
        this.globalSrvc.darkModeToggleState.subscribe(value => {
            this.dark = value;
            console.log(value);
        });
        this.dark = localStorage.getItem('dark') === 'true' ? true : false;

       /* this.fcm.onNotification().subscribe(data => {

            console.log(data);
       
           if (data.wasTapped) {
       
             console.log('Received in background');
       
           } else {
       
             console.log('Received in foreground');
       
           }
       
         });*/
        this.initializeApp();
    }

   async ngOnInit() {
        await this.storage.create();
    }

   initializeApp() {

        //this.authService.init();
        this.platform.ready().then(async () => {
            
          /* To make sure we provide the fastest app loading experience 
       for our users, hide the splash screen automatically 
       when the app is ready to be used:

        https://capacitor.ionicframework.com/docs/apis/splash-screen#hiding-the-splash-screen
    */
        const { SplashScreen, StatusBar }: PluginRegistry = Plugins;

        if (Capacitor.isPluginAvailable('StatusBar')) { StatusBar.show() };

            // ok, what this will do is to as soon as the app is open, it will check for the device language, only if the language was not set before
            // clear? yes so in the if we have to put all languages that i translated right?correct
            let language = this.userData.getSession('language');
            if (language && language.length != 2) {
                this.userData.getDeviceLanguage();
            }
            
        });
        if (this.platform.is('cordova')) {
            setTimeout(() => {
                //this.userData.registerFCM();
            }, 4500);
        }
    }

}
