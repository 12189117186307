import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { AppData } from '../app-data/app-data';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Plugins } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { FCM } from '@capacitor-community/fcm';
import Swal from 'sweetalert2';
import { Events } from '../../services/events/events';
import { map } from 'rxjs/operators';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserData {
    favorites: string[] = [];
    HAS_LOGGED_IN = 'hasLoggedIn';
    HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
    options: any;
    api_signature: string = '';

    constructor(
        public storage: Storage,
        public appData: AppData,
        public platform: Platform,
        public http: HttpClient,
        //public device: Device,
        private globalization: Globalization,
        public events: Events,
        //public fcm: FCM,
        //private push: Push,
        // private fb: Facebook
    ) {
        this.options = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        this.api_signature = window.localStorage.getItem(appData.getPrefix() + 'api_signature');
    }

    hasFavorite(sessionName: string): boolean {
        return (this.favorites.indexOf(sessionName) > -1);
    }

    addFavorite(sessionName: string): void {
        this.favorites.push(sessionName);
    }

    removeFavorite(sessionName: string): void {
        const index = this.favorites.indexOf(sessionName);
        if (index > -1) {
            this.favorites.splice(index, 1);
        }
    }

    /*login(username: string): Promise<any> {
      return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
        this.setUsername(username);
        return window.dispatchEvent(new CustomEvent('user:login'));
      });
    }*/
    jsonToURLEncoded(obj) { // this is ok, you need to still add some code in the api, but for apple is just to be available in the app ok got what this does is send a notificaion on phone to say what you want to say api wise like simon created a new media? correctok so
        var query = '', name, value, fullSubName, subName, subValue, innerObj, i;
        for (name in obj) {
            value = obj[name];

            if (value instanceof Array) {
                for (i = 0; i < value.length; ++i) {
                    subValue = value[i];
                    fullSubName = name + '[' + i + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += this.jsonToURLEncoded(innerObj) + '&';
                }
            }
            else if (value instanceof Object) {
                for (subName in value) {
                    subValue = value[subName];
                    fullSubName = name + '[' + subName + ']';
                    innerObj = {};
                    innerObj[fullSubName] = subValue;
                    query += this.jsonToURLEncoded(innerObj) + '&';
                }
            }
            else if (value !== undefined && value !== null)
                query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
        }//api is one on server phph slim is

        return query.length ? query.substr(0, query.length - 1) : query;
    }

    getLanguage() {
        let language = 'en';

        if (this.getSession('language')) {
            language = this.getSession('language');
        } else if (window.Intl && typeof window.Intl === 'object') {
            this.setSession('language', navigator.language);
            language = navigator.language;
        }

        language = language.substr(0, 2);

        return language;
    }

    getDeviceLanguage() {
        this.globalization.getPreferredLanguage()
            .then(res => {
                let lang = navigator.language;
                lang = lang ? lang.substr(0, 2) : 'en';

                let translated = ['es', 'fr', 'en', 'de', 'el', 'it', 'ru'];

                if (!translated.includes(lang)) {
                    lang = 'en';
                }

                this.setSession('language', navigator.language);
            })
            .catch(e => { console.log(e); });
    }

    login(login_data) {
        const url = this.appData.getApiUrl() + 'login';
        const data = this.jsonToURLEncoded({
            login_data: login_data,
            app_version: this.appData.APP_VERSION
        });
        return this.http.post(url, data, { headers: this.options });
    };

    getSession(elem: string) {
        return window.localStorage.getItem(this.appData.getPrefix() + elem);
    };

    setSession(elem: string, val: any) {
        window.localStorage.setItem(this.appData.getPrefix() + elem, val);
    };

    removeSession(elem: string) {
        window.localStorage.removeItem(this.appData.getPrefix() + elem);
    };

    getUserBySignature(api_signature: any) {
        const url = this.appData.getApiUrl() + 'getUserBySignature';
        const data = this.jsonToURLEncoded({
            api_signature: api_signature
        });
        return this.http.post(url, data, { headers: this.options });
    }

    setUserData(user: any) {
        this.setSession('user', JSON.stringify(user));
        this.setSession('api_signature', user.api_signature); // it'll be dynamic man so?
        this.api_signature = user.api_signature;
    };

    getUserData() {
        return JSON.parse(this.getSession('user'));
    };

    hasLoggedIn() {
        return this.getSession('HAS_LOGGED_IN') == 'true';
    };

    signup(signup_data: any) {

        const url = this.appData.getApiUrl() + 'signup';
        const data = this.jsonToURLEncoded({
            signup_data: signup_data,
            app_version: this.appData.APP_VERSION
        });

        return this.http.post(url, data, { headers: this.options });
    };
    logout(): Promise<any> {
        this.removeSession('HAS_LOGGED_IN');
        return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
            return this.storage.remove('username');
        }).then(() => {
            window.dispatchEvent(new CustomEvent('user:logout'));
        });
    }

    setUsername(username: string): Promise<any> {
        return this.storage.set('username', username);
    }

    getUsername(): Promise<string> {
        return this.storage.get('username').then((value) => {
            return value;
        });
    }

    isLoggedIn(): Promise<boolean> {
        return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
            return value === true;
        });
    }

    checkHasSeenTutorial(): Promise<string> {
        return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
            return value;
        });
    }




    allArtists(offset: number) {
        const url = this.appData.getApiUrl() + 'allArtists';
        /*
        const requestOptions = {
          params: new HttpParams()
        };
        */
        if (this.api_signature) {
            const data = this.jsonToURLEncoded({
                api_signature: this.api_signature,
                offset: offset
            });
            return this.http.post(url, data, { headers: this.options });
        } else {
            const data = this.jsonToURLEncoded({
                api_signature: '8f2f39ecb1ab9ec5a31728d45c05759d',//this is guest got itok continue ok
                offset: offset
            });
            return this.http.post(url, data, { headers: this.options });
            //return this.http.post(url, data, requestOptions );
        }
    }

    allMedias(offset: number) {
        const url = this.appData.getApiUrl() + 'allMedia';
        if (this.api_signature) {
            const data = this.jsonToURLEncoded({
                api_signature: this.api_signature,
                offset: offset
            });
            return this.http.post(url, data, { headers: this.options });
        }
        else {
            const data = this.jsonToURLEncoded({
                api_signature: '8f2f39ecb1ab9ec5a31728d45c05759d',
                offset: offset
            });
            return this.http.post(url, data, { headers: this.options });
        }

    }

    topMedias(offset: number) {
        const url = this.appData.getApiUrl() + 'topMedia';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    topReviews(offset: number) {
        const url = this.appData.getApiUrl() + 'topReviews';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    };

    allFlow(offset: number) {
        const url = this.appData.getApiUrl() + 'allFlows';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    friendsList(uid, offset: number) {
        const url = this.appData.getApiUrl() + 'friendsList';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            uid: uid,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    mediasList(uid, offset: number) {
        const url = this.appData.getApiUrl() + 'mediaList';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            uid: uid,
            offset: offset
        });

        return this.http.post(url, data, { headers: this.options });
    }

    mediaAdList(offset: number) {
        const url = this.appData.getApiUrl() + 'adDetails';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset
        });

        return this.http.post(url, data, { headers: this.options });
    }


    flowsList(uid, offset: number) {
        const url = this.appData.getApiUrl() + 'flowsList';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            uid: uid,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    createFlow(flow_data: any) {
        const url = this.appData.getApiUrl() + 'createFlow';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            flow_data: flow_data
        });
        return this.http.post(url, data, { headers: this.options });
    };

    updateFlow(group_id, flow_data: any) {
        const url = this.appData.getApiUrl() + 'updateFlow';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            group_id: group_id,
            flow_data: flow_data
        });
        return this.http.post(url, data, { headers: this.options });
    };

    flowDetails(flow) {
        const url = this.appData.getApiUrl() + 'flowDetails';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            flow: flow
        });

        return this.http.post(url, data, { headers: this.options });
    }

    getActivityFlow(flow_id: number, offset: number) {
        const url = this.appData.getApiUrl() + 'getActivityFlow';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            flow_id: flow_id,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    getActivityUser(offset: number, uid: number) {
        console.log(this.api_signature);
        let url = this.appData.getApiUrl() + 'getActivityUser';
        let data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset,
            uid: uid
        });

        return this.http.post(url, data, { headers: this.options });
    }

    getActivityMedia(msg_id: number, offset: number) {
        console.log(this.api_signature);
        let url = this.appData.getApiUrl() + 'getActivityMedia';
        let data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset,
            msg_id: msg_id
        });

        return this.http.post(url, data, { headers: this.options });
    }

    uploadText(media: any, group_id: number) {
        const url = this.appData.getApiUrl() + 'uploadText';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            media: media,
            group_id: group_id
        });
        return this.http.post(url, data, { headers: this.options });
    }

    mediaOnFlowProfile(flow) {
        const url = this.appData.getApiUrl() + 'mediaFlowProfile';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            flow: flow
        });
        return this.http.post(url, data, { headers: this.options });
    }

    flowMembers(flow) {
        const url = this.appData.getApiUrl() + 'flowMembers';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            flow: flow
        });
        return this.http.post(url, data, { headers: this.options });
    }

    searchUser() {
        const url = this.appData.getApiUrl() + 'userGroupSearch';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature
        });
        return this.http.post(url, data, { headers: this.options });
    }

    homeFeed(offset: number) {
        const url = this.appData.getApiUrl() + 'newsFeed';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    followUser(fid: number, username: string) {
        const url = this.appData.getApiUrl() + 'addFriend';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature, // I think we can keep the changes here in the ionic part, and then just use the old php version ok so it will work now? i mean this?
            fid: fid,
            username: username // so all parameters you put here will be avialabel in $_POST in the server leave this comment
        });
        return this.http.post(url, data, { headers: this.options });
    }

    unfollowUser(fid: number) {
        const url = this.appData.getApiUrl() + 'removeFriend';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            fid: fid
        });
        return this.http.post(url, data, { headers: this.options });
    }

    mediaEditors(msg_id: number, offset: number) {
        const url = this.appData.getApiUrl() + 'mediaEditors';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            msg_id: msg_id,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    addMediaReport(report: any) {//user data function
        const url = this.appData.getApiUrl() + 'mediaReport';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            report: report
        });
        return this.http.post(url, data, { headers: this.options });
    };

    addUserReport(report: any) {//user data function
        const url = this.appData.getApiUrl() + 'userReport';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            report: report
        });
        return this.http.post(url, data, { headers: this.options });
    };

    peopleWhoViewedMedia(uid: number, offset: number) {
        const url = this.appData.getApiUrl() + 'peopleWhoViewedMedia';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            uid: uid,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    peoplePoints(msg_id: number, offset: number) {
        const url = this.appData.getApiUrl() + 'peopleGivePoints';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            msg_id: msg_id,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    mediaDelete(msg_id: number) {
        const url = this.appData.getApiUrl() + 'mediaDelete';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            msg_id: msg_id
        });
        return this.http.post(url, data, { headers: this.options });
    }

    mediaDetail(msg_id) {
        const url = this.appData.getApiUrl() + 'mediaDetails';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            msg_id: msg_id
        });

        return this.http.post(url, data, { headers: this.options });
    }

    deActivate() {
        const url = this.appData.getApiUrl() + 'userDeactivate';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature
        });
        return this.http.post(url, data, { headers: this.options });
    }

    reviewMedia(review_data: any) {//and this the function that ends to apiok
        const url = this.appData.getApiUrl() + 'reviewMedia';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            review_data: review_data
        });
        return this.http.post(url, data, { headers: this.options });
    };

    givePoints(media: any, points: number) {
        let url = this.appData.getApiUrl() + 'givePoints';
        let data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            media: media,
            points: points
        });

        return this.http.post(url, data, { headers: this.options });
    }

    updatePoints(media: any, points: number, pointsOld: number) {
        const url = this.appData.getApiUrl() + 'updateUserPoints';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            media: media,
            points: points,
            pointsOld: pointsOld
        });
        return this.http.post(url, data, { headers: this.options });
    }

    getMessages(offset: number) {
        const url = this.appData.getApiUrl() + 'getMessages';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    setNotification(id_notification: number) {
        const url = this.appData.getApiUrl() + 'getMessages';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            id_notification: id_notification
        });
        return this.http.post(url, data, { headers: this.options });
    }

    getNotifications(offset: number) {
        const url = this.appData.getApiUrl() + 'getNotifications';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    deleteNotifications(id_notification: number, deleted: boolean) {
        const url = this.appData.getApiUrl() + 'deleteNotification';
        const data = this.jsonToURLEncoded({
            id_notification: id_notification,
            deleted: deleted
        });
        return this.http.post(url, data, { headers: this.options });
    }

    getMediaReviews(msg_id: number) {
        const url = this.appData.getApiUrl() + 'getMediaReviews';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            msg_id: msg_id
        });

        return this.http.post(url, data, { headers: this.options });
    };

    changePassword(password: string) {
        const url = this.appData.getApiUrl() + 'changePassword';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            password: password
        });
        return this.http.post(url, data, { headers: this.options });
    }

    updateUserSettings(user: any) {
        const url = this.appData.getApiUrl() + 'updateUserSettings';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            user: user
        });
        return this.http.post(url, data, { headers: this.options });
    }

    getSettings() {
        const url = this.appData.getApiUrl() + 'getSettings';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
        });
        return this.http.post(url, data, { headers: this.options });
    }

    registerFCM_old() {
/*
        const options: PushOptions = {
            android: {},
            ios: {
                alert: 'true',
                badge: true,
                sound: 'false'
            },
            windows: {},
            browser: {
                pushServiceURL: 'http://push.api.phonegap.com/v1/push'
            }
        }

        const pushObject: PushObject = this.push.init(options);

        pushObject.on('registration').subscribe((registration: any) => {
            console.log('Device registered', registration)
            this.submitFCM(registration);
        });

        pushObject.on('notification').subscribe((data: any) => {
            console.log('Received a data', data)

            if (data.message) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    backdrop: false,
                    timer: 2500
                })
            }

            if (data.refresh) {
                if (this.hasLoggedIn()) {
                    this.setUserData(data.user);
                    this.events.publish('user:updated');
                }
            }
        });


        // this.fcm.subscribeToTopic('notifications');

        // this.fcm.getToken().then(token => {
        //   this.submitFCM(token);
        // });


        // this.fcm.onNotification().subscribe(data => {
        //   if (data.wasTapped) {
        //     console.log("Received in background");
        //   } else {
        //     console.log("Received in foreground");
        //   };
        //   console.log(data);
        //   if (data.message) {
        //     Swal.fire({
        //       icon: 'success',
        //       title: data.message,
        //       showConfirmButton: false,
        //       backdrop: false,
        //       timer: 2500
        //     })
        //   }

        //   if (data.refresh) {
        //     if (this.hasLoggedIn()) {
        //       this.setUserData(data.user);
        //       this.events.publish('user:updated');
        //     }
        //   }
        // });

        // this.fcm.onTokenRefresh().subscribe(token => {
        //   this.submitFCM(token);
        // })
        */
    }

  /*  registerFCM() {
        this.fcm.requestPushPermission();


        this.fcm.getToken().then(token => {
            console.log('token FCM', token);
            console.log(token);
            this.submitFCM(token);
        });

        this.fcm.onNotification().subscribe(data => {
            if (data.wasTapped) {
                console.log("Received in background");
            } else {
                console.log("Received in foreground");
            };

            console.log('Received a data', data)

            if (data.message) {
                Swal.fire({
                    icon: 'success',
                    title: data.message,
                    showConfirmButton: false,
                    backdrop: false,
                    timer: 2500
                })
            }

            if (data.refresh) {
                if (this.hasLoggedIn()) {
                    this.setUserData(data.user);
                    this.events.publish('user:updated');
                }
            }
        });

        this.fcm.onTokenRefresh().subscribe(token => {
            this.submitFCM(token);
        });
    }

*/
    submitFCM(token) {
        console.log('token', token);// that's why the livereload is good, because you don't need to build again to see

        let url = this.appData.getApiUrl() + 'registerFCM'; // we need to create this function
        let data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            registrationId: token,
            app_version: this.appData.APP_VERSION,

            /*const logDeviceInfo = async () => {
                const info = await Device.getInfo();
              
                platform: logDeviceInfo.platform,
                uuid: this.device.uuid,
                cordova: this.device.cordova,
                model: this.device.model,
                manufacturer: this.device.manufacturer,
                version: this.device.version,
              };
              */

           /* device: {
                platform: this.device.platform,
                uuid: this.device.uuid,
                cordova: this.device.cordova,
                model: this.device.model,
                manufacturer: this.device.manufacturer,
                version: this.device.version,
            }*/
        });

        this.http.post(url, data, { headers: this.options })
        .subscribe(data => {
            console.log('Push has been registered', data);
        })
    }

    appleLogin(login_data) {
        let device = {};

        if (this.platform.is('cordova')) {
            /*device = {
                platform: this.device.platform,
                uuid: this.device.uuid,
                cordova: this.device.cordova,
                model: this.device.model,
                manufacturer: this.device.manufacturer,
                version: this.device.version,
            };*/
        }

        var timezone_offset = new Date().getTimezoneOffset();
        timezone_offset = timezone_offset == 0 ? 0 : -timezone_offset;

        const url = this.appData.getApiUrl() + 'sign_in_with_apple';
        const data = this.jsonToURLEncoded({
            login_data: login_data,
            device: device,
            app_version: this.appData.APP_VERSION,
            timezone_offset: timezone_offset
        });

        return this.http.post(url, data, { headers: this.options });
    };

    facebookLogin(login_data) {
        let device = {};

        if (this.platform.is('cordova')) {
          /*  device = {
                platform: this.device.platform,
                uuid: this.device.uuid,
                model: this.device.model,
                manufacturer: this.device.manufacturer,
                version: this.device.version,
            };*/
        }

        var timezone_offset = new Date().getTimezoneOffset();
        timezone_offset = timezone_offset == 0 ? 0 : -timezone_offset;

        const url = this.appData.getApiUrl() + 'facebookLoginDesktop';
        const data = this.jsonToURLEncoded({
            login_data: login_data,
            device: device,
            app_version: this.appData.APP_VERSION,
            timezone_offset: timezone_offset
        });
        return this.http.post(url, data, { headers: this.options });
    };

    //this one man so i add this in login? and it needs an api function right? you need to get data from this as you see below
    googleLogin(login_data) {
        let device = {};

        if (this.platform.is('cordova')) {
           /* device = {
                platform: this.device.platform,
                uuid: this.device.uuid,
                model: this.device.model,
                manufacturer: this.device.manufacturer,
                version: this.device.version,
            };*/
        }

        var timezone_offset = new Date().getTimezoneOffset();
        timezone_offset = timezone_offset == 0 ? 0 : -timezone_offset;

        const url = this.appData.getApiUrl() + 'googleLogin';
        const data = this.jsonToURLEncoded({
            login_data: login_data,
            device: device,
            app_version: this.appData.APP_VERSION,
            timezone_offset: timezone_offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    userProfile() {
        const url = this.appData.getApiUrl() + 'updateNewsFeed';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
        });

        return this.http.post(url, data, { headers: this.options });
    }

    userDetails(uid) {
        const url = this.appData.getApiUrl() + 'userDetails';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            uid: uid
        });
        return this.http.post(url, data, { headers: this.options });
    }

    usersMedia(uid, offset: number) {
        const url = this.appData.getApiUrl() + 'usersMedia';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            uid: uid,
            offset: offset
        });

        return this.http.post(url, data, { headers: this.options });
    }

    usersFriends(uid, offset: number) {
        const url = this.appData.getApiUrl() + 'usersFriends';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            uid: uid,
            offset: offset
        });

        return this.http.post(url, data, { headers: this.options });
    }

    usersFlow(uid: any, offset: number) {
        const url = this.appData.getApiUrl() + 'usersFlow';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            uid: uid,
            offset: offset
        });
        return this.http.post(url, data, { headers: this.options });
    }

    saveProfile(user) {
        const url = this.appData.getApiUrl() + 'saveProfile';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            user: user
        });
        return this.http.post(url, data, { headers: this.options });
    }

    sendMedia(msgId: number, userOne: number, userTwo: number) {
        const url = this.appData.getApiUrl() + 'sendMessage';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature, // I think we can keep the changes here in the ionic part, and then just use the old php version ok so it will work now? i mean this?
            msgId: msgId,
            user_one: userOne,
            user_two: userTwo
        });
        return this.http.post(url, data, { headers: this.options });
    }

    changeStatus(msg_id: number, status: boolean) {
        const url = this.appData.getApiUrl() + 'changeStatus';
        const data = this.jsonToURLEncoded({
            msg_id: msg_id,
            status: status
        });
        return this.http.post(url, data, { headers: this.options });
    }

    creativeInterestsUser(uid:number) {
        const url = this.appData.getApiUrl() + 'getCreativeInterestsUser';
        const data = this.jsonToURLEncoded({
            uid: uid
        });
        return this.http.post(url, data, { headers: this.options });
    }

    artistsInterestsUser(uid:number) {
        const url = this.appData.getApiUrl() + 'getArtistInterestsUser';
        const data = this.jsonToURLEncoded({
            uid: uid
        });
        return this.http.post(url, data, { headers: this.options });
    }
    creativeInterests(uid:number) {
        const url = this.appData.getApiUrl() + 'getCreativeInterests';
        const data = this.jsonToURLEncoded({
            uid: uid
        });
        return this.http.post(url, data, { headers: this.options });
    }

    artistsInterests(uid:number) {
        const url = this.appData.getApiUrl() + 'getArtistInterests';
        const data = this.jsonToURLEncoded({
            uid: uid
        });
        return this.http.post(url, data, { headers: this.options });
    }

    creativeSettings(uid: number, id_interest:any, active: boolean) {
        const url = this.appData.getApiUrl() + 'creativeSettings';
        const data = this.jsonToURLEncoded({
            uid: uid,
            id_interest: id_interest,
            active: active
        });
        return this.http.post(url, data, { headers: this.options });
    }

    artistSettings(uid: number, id_artist_interest:any, active: boolean) {
        const url = this.appData.getApiUrl() + 'artistSettings';
        const data = this.jsonToURLEncoded({
            uid: uid,
            id_artist_interest: id_artist_interest,
            active: active
        });
        return this.http.post(url, data, { headers: this.options });
    }

    flowViewed(flow: any) {
        const url = this.appData.getApiUrl() + 'flowViewed';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            flow: flow
        });
        return this.http.post(url, data, { headers: this.options });
    }

    mediaViewed(media: any) {
        const url = this.appData.getApiUrl() + 'mediaViewed';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            media: media
        });
        return this.http.post(url, data, { headers: this.options });
    }

    joinFlow(fid: number, flowname: string) {
        const url = this.appData.getApiUrl() + 'joinFlow';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            fid: fid,
            flowname: flowname
        });

        return this.http.post(url, data, { headers: this.options });
    }

    removeFlow(fid: number) {
        const url = this.appData.getApiUrl() + 'removeFlow';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            fid: fid
        });
        return this.http.post(url, data, { headers: this.options });
    }

    showMessages(offset: number) {
        const url = this.appData.getApiUrl() + 'userMessages';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            offset: offset
        });

        return this.http.post(url, data, { headers: this.options });
    }

    singleMessage(message: any) {
        const url = this.appData.getApiUrl() + 'singleMessage';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            message: message//what does mssage has as 
        });
        return this.http.post(url, data, { headers: this.options });
    }

    sendMessage(userOne: number, userTwo: number, message_data) {
        const url = this.appData.getApiUrl() + 'sendMessage';//SE STILL PASS CID IT SHOULD PASS USER_ONE AND USER_TWO RECIEICER AND SENDER ID RIGHT?ok
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature, // I think we can keep the changes here in the ionic part, and then just use the old php version ok so it will work now? i mean this?
            user_one: userOne,
            user_two: userTwo,
            message_data: message_data
        });
        console.log(data);
        return this.http.post(url, data, { headers: this.options });
    }

    recoverPassword(email: string) {
        const url = this.appData.getApiUrl() + 'recoverPassword';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            email: email
        });
        return this.http.post(url, data, { headers: this.options });
    }

    editMedia(media_data: any) {
        const url = this.appData.getApiUrl() + 'editMedia';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            mediaData: media_data
        });

        return this.http.post(url, data, { headers: this.options });
    };

    getFileExtension(fileName: string) {
        const filExtension = fileName.substr(fileName.lastIndexOf('.') + 1);
        return filExtension;
    }

    requestForPayment(request): Observable<any> {
        const url = this.appData.getApiUrl() + 'requestForPayment';
        const data = this.jsonToURLEncoded({
            api_signature: this.api_signature,
            payment: request
        });

        /* yes man i know ill doOKSORRY np mediaData what will be here ?YOU S AID YOU KNEW MAN HAHA  I know the params where to pss but dont know 
        what requirest params because its backend thing*/

        return this.http.post(url, data, { headers: this.options });
    }

}