import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { AppData } from './services/app-data/app-data';
import { UserData } from './services/user-data/user-data';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';

import { TranslateConfigService } from './services/translate/translate.service';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { IonicStorageModule } from '@ionic/storage-angular';
import { StreamingMedia } from '@awesome-cordova-plugins/streaming-media/ngx';
import { MediaCapture} from '@awesome-cordova-plugins/media-capture/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { UserProfileModalPageModule } from './pages/user-profile-modal/user-profile-modal.module';
import { UserProfilePopoverComponent } from './components/user-profile-popover/user-profile-popover.component';
import { MetaModule } from '@ngx-meta/core';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}//do some cap sync? is ok to do it at the endok

@NgModule({
  declarations: [AppComponent, UserProfilePopoverComponent],
  entryComponents: [UserProfilePopoverComponent],
  imports: [
    BrowserModule,
    MetaModule.forRoot(),
    IonicModule.forRoot(
      { animated: false }
    ),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    LazyLoadImageModule,
    IonicStorageModule.forRoot(),
    //IonMarqueeModule,
    FormsModule,
    ReactiveFormsModule,
    UserProfileModalPageModule
  ],
  providers: [
    AppData,
    UserData,
    TranslateConfigService,
    StreamingMedia,
    MediaCapture,
    FileTransfer,
    Camera,
    Meta,
    Globalization,
    TranslateService,
    SocialSharing,
    Geolocation,
    NativeGeocoder,
    File,
    FileTransfer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
